
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.authors--select {
    max-width: min-content !important;
    padding: 0;
    margin: 0;
    min-width: 200px;
    position: relative;
    top: -5px;
}
